import { api } from '../index'
import { Competition } from './competitions'
import { Match } from './matches'

export enum RoundCategory {
  REGULAR_SEASON = 'Regular season',
  FINALS = 'Finals',
}

export interface Round {
  roundCategory: RoundCategory
  roundNumber: number
  date?: string
}

export interface DrawBuilder {
  _id: string
  ttr_id: number
  timeSlots: string[]
  competition: Competition
  gamesPerSlot: number
  roundType?: string
  rounds: Round[]
  matches: Match[]
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type DrawBuilderResponse = DrawBuilder[]

export const drawBuilderApi = api.injectEndpoints({
  endpoints: build => ({
    getDrawBuilders: build.query<DrawBuilderResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'draw-builders/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['draw-builders'],
    }),
    getDrawBuilder: build.query<DrawBuilder, string>({
      query: id => `draw-builders/${id}`,
      providesTags: ['draw-builder'],
    }),
    addDrawBuilder: build.mutation({
      query: body => ({
        url: 'draw-builders',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['draw-builder'],
    }),
    updateDrawBuilder: build.mutation({
      query: body => ({
        url: `draw-builders/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['draw-builder', 'draw-builders'],
    }),
    deleteDrawBuilder: build.mutation({
      query: id => ({
        url: `draw-builders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['draw-builder', 'draw-builders'],
    }),
  }),
})

export const {
  useLazyGetDrawBuildersQuery,
  useGetDrawBuildersQuery,
  useGetDrawBuilderQuery,
  useAddDrawBuilderMutation,
  useDeleteDrawBuilderMutation,
  useUpdateDrawBuilderMutation,
} = drawBuilderApi
